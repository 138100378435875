.header {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 500;
}

.nav {
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.nav-section.logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: 100px;
}


.nav-section.primary {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}


@media (max-width: 630px) {
  .nav-section.primary {
    display: none;
  }
}

.nav-link {
  padding: 0 2em;
  font-size: 17px;
  line-height: 50px;
  text-decoration: none;
  color: white;
}
.nav-link:hover {
  color: var(--secondary-colour);
}

.nav-section.mobile {
  display: none; /* TODO: Mobile menu! */
}

.moblyx-logo {
  display: block;
  max-width: 300px;
  padding: 1em 0;
}
.moblyx-logo h1 {
  margin: 0;
}

.moblyx-logo img {
  display: block;
  max-width: 100%;
}