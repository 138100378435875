* {
  box-sizing: border-box;
}

/* GLOBAL STYLES */
:root {
  --strongest-primary-colour: #0a0a0a;
  --stronger-primary-colour: #102231;
  --strong-primary-colour: #1c3b55;
  --primary-colour: #408ccb;
  --light-primary-colour: #e6f4ff;
  --component-primary-colour: #408ccb;
  --lighter-component-primary-colour: #90caf2;
  --primary-colour: #408ccb;
  --secondary-colour: rgb(233, 86, 41);
  --page-width: 1280px;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}


.moblyx-centre {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 0.3em 0;
}

.moblyx-button {
  display: inline-block;
  padding: 1em 2.1em;

  color: white;
  background-color: var(--primary-colour);
  text-transform: uppercase;
  text-decoration: none;

  border-radius: 0.3em;
  box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.38);
  border: 1px solid rgb(64, 140, 203);

  transition: all 0.3s ease-in;
}

.moblyx-button:hover {
  box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.38);
  transform: translateY(-1px);
}


.big-purco .intro {
  /* padding-top: 2em; */
  /* align-items: flex-start;; */
}

.big-purco .landing-title {
  margin-bottom: -300px;
}

.purco-branding {
  position: relative;
}

.purco-branding > div {
  display: flex;
  justify-content: center;
}


.purco-branding .claims-block {
  margin: 0 auto;
  height: auto;
  max-width: 850px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.purco-branding h2 {
  text-align: center;
}
.purco-branding p {
  text-align: center;
  font-size: 1.3em;
  font-weight: 200;
}
.purco-branding svg {
  max-width: 350px;
  max-height: 200px;
}

.purco-interest-wrapper {
  position: relative;
  margin-top: -200px;
}

.purcos-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

}
.purcos {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #102231;
  background: linear-gradient(135deg, rgba(16,34,49,1) 7%, rgba(28,59,85,1) 80%);
  transform-origin: 100% 0;
  transform: skewY(-7deg);
  overflow: hidden;

  background-size: cover;
  transition: background 1.5s linear;

  animation: fadein 0.1s;
}
.purcos:before {
  content: '';
  display: block;
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;

  background: linear-gradient(135deg, #990000 7%, #002262 80%);
  opacity: 0;
  animation: opacity-flick 10s alternate infinite;
}
@keyframes opacity-flick {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.purco-interest-section {
  position: relative;
  padding: 250px 0;
}
.purco-interest {
  position: relative;
  margin: 0 auto;
  max-width: 600px;
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06);
}

.purco-interest-heading {
  max-width: 600px;
}
.purco-interest-title {
  margin: 0;;
  margin-bottom: 5px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.89);
}
.purco-interest-subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 200;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #dfdfdfea;
}

.purco-form {
  display: flex;
  flex-direction: column;
}

.purco-form label {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  color: rgb(48, 48, 48);
  margin-bottom: 15px;
}

.purco-form input,
.purco-form select,
.purco-form textarea {
  margin-top: 3px;
  font-size: 14px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #CACACA;
  line-height: 25px;
  min-height: 35px;;
}

.purco-form textarea {

}

.purco-form button {
  background-color: #408ccb;
  border: 0;
  padding: 5px;
  flex: 0 1 auto;
  width: auto;
  height: 35px;
  margin-top: 20px;
}

.purco-contact-us {
  margin: 0 auto;
  margin-top: 5px;
}
.purco-contact-us a {
  margin-left: 5px;
  color: var(--primary-colour);
}

.purco-branding a {
  color: var(--primary-colour);
}