/* TITLE - START */

.landing-title {
  position: relative;
}

.moblyxs {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1c3b55;
  background-color: #102231;
  background: radial-gradient(circle, rgba(28,59,85,1) 7%, rgba(16,34,49,1) 76%);
  background: linear-gradient(135deg, rgba(16,34,49,1) 7%, rgba(28,59,85,1) 80%);
  transform-origin: 0;
  transform: skewY(-7deg);

  background: url('../../assets/backgrounds/background-1.jpg'), linear-gradient(135deg, rgba(16,34,49,1) 7%, rgba(28,59,85,1) 80%);
  background-size: cover;
  background-attachment: fixed;
  transition: background 1.5s linear;

  animation: fadein 0.1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.intro {
  position: relative;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.intro-container {
  width: 100%;
}

.intro-title {
  font-size: 2.1em;
  font-weight: 200;
  margin: 40px 0;
  text-shadow: 0px 1px 2px rgb(49, 48, 48);
  text-align: center;
}

.intro-nonsense {
  font-size: 1.2em;
  font-weight: 200;
  max-width: 600px;
  text-shadow: 0px 1px 2px rgb(49, 48, 48);
  text-align: center;
}

.intro-buttons {
  margin: 40px 0;
}

.intro-buttons > .moblyx-button {
  background-color: var(--secondary-colour);
}


/* TITLE - END */

/* CLAIMS - START */

.claims-section {
  margin: 120px 0;
}

.claims-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: -0.3em;
}

.claims-list > li {
  flex: 1 1 100%;
  margin: 0.3em;
}

.claims-block {
  height: 210px;
  border-radius: 0.3em;
  padding: 0.6em;
  border: 0;
  box-shadow: 0 2px 8px rgba(139,133,133,.35);
  background-color: #fff;
  overflow: hidden;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

/* CLAIMS - END */

/* MINSPECT - START */

.minspect-section {
  margin: 60px 0;
}

.minspect-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.minspect-logo {
  height: 100px;
  max-width: 100%;
}
.minspect-logo > img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.purinspect-logo {
  height: 150px;
  max-width: 100%;
}
.purinspect-logo > img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.minspect-title {
  margin: 45px;
}
.minspect-blurb {
  text-align: center;
  font-size: 1.3em;
  max-width: 900px;
  font-weight: 200;
  width: 100%;
}

.minspect-summary {
  margin: 45px 0;
}

.points {
  font-size: 1.1em;
  font-weight: 200;
}
.points > li {
  padding: 1.2em 0.9em;
}

.point-title {
  display: block;
  margin: 0;
  font-weight: 600;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}
.container > * {
  margin: 20px;
}

.minspect-summary-points {
  flex: 1 1 350px;
  align-self: center;
}
.minspect-summary-example {
  flex: 0 1 100%;
  max-width: 600px;
  background-color: white;
  transform: scale(1) perspective(1040px) rotateY(-20deg) rotateX(2deg) rotate(2deg);
  box-shadow: 0 2px 20px rgba(139,133,133,.35);
  border: 1px solid #b3b3b3;
  border-radius: 0.3em;
  padding: 0.5em;
  z-index: 1;
}

.summary-example-image-con > img {
  display: inline-block;
  max-width: 100%;
}

.minspect-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px 0;
}
.minspect-buttons > * {
  margin: 0.3em;
}

.promotional-video {
  width: 720px;
  height: 405px;
  overflow: hidden;
}

.promotional-text {
  flex: 1 1 0;
  align-self: center;
  font-size: 1.4em;
  font-weight: 200;
  min-width: 300px;
}

.moblyx-button.minspect-login {
  background-color: white;
  color: black;
  border: 1px solid black;
  box-shadow: 0 0 0;
}

/* MINSPECT - END */

/* MCARPOOL - START */

.mcarpool-summary-example {
  flex: 0 1 300px;
  transform: scale(1) perspective(1040px) rotateY(22deg) rotateX(3deg) rotate(-2deg);

  box-shadow: 0 2px 20px rgba(139,133,133,.35);
  border: 1px solid #b3b3b3;
  border-radius: 0.3em;
  padding: 0.5em;
}

/* MCARPOOL - END */

/* FOOTER - START */


.via-footer {
  flex: 0 0 auto;
  background-color: var(--stronger-primary-colour);
  color: white;
  padding: 0;
  font-size: 14px;
}

.via-footer a {
  color: rgba(255, 255, 255, 0.781);
}
.via-footer a:hover {
  color: red;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: -2px -4px;
  white-space: nowrap;
  font-size: 12px;
}
.footer-links > li {
  line-height: 12px;
}
.footer-links > li + li:before {
  content: '·';
  padding: 4px;
}

/* First Section */
.footer-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--strong-primary-colour);
  font-size: 15px;
  padding: 0.9em 0;
}

/* Second Section */
.footer-secondary {
  background-color: var(--stronger-primary-colour);
  padding: 1.6em;
}

.footer-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-group {
  flex: 0 0 300px;
  margin: 0.9em;
}
.footer-group-title {
  color: var(--via-footer-text-colour);
  font-weight: 600;
  margin-bottom: 0.3em;
}
.footer-group-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-group-list > li {
  line-height: 1.6;
}

/* Third Section */
.footer-tertiary {
  /* background-color: var(--strongest-primary-colour); */
  padding: 0.6em;
}

.footer-tertiary-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.via-footer-logo {
  width: auto;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.via-footer-logo > img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.footer-copyright {
  color: rgb(203, 203, 203);
  font-size: 0.9em;
}



/* FOOTER - END */
